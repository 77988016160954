<template>
  <div class="oracle" :class="{ opened, loading }">
    <DeployerListOracleRow
      :oracle="oracle"
      :api-data="apiData"
      :loading="loading"
      :read-mode="props.readMode"
      @open-info="openInfo"
    />
    <div class="info">
      <div class="inner">
        <DeployerListOracleInfo
          v-if="apiData"
          :api-data="apiData"
          :oracle="oracle"
          :read-mode="props.readMode"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  oracle: Object,
  readMode: {
    type: Boolean,
    default: false
  }
})

const newOraclesStore = useNewOraclesStore()

const config = useRuntimeConfig()

const opened = ref(false)
const loading = ref(true)
const apiData = ref(null)

const now = Math.floor(Date.now() / 1000)

const loadOracleData = async () => {
  apiData.value = await $fetch(
    `${config.public.oracleApiBaseURL}/oraclebuilder-prod/dashboard?address=${
      props.oracle.address
    }&chainID=${props.oracle.chainId}&starttime=${now - 60}&endtime=${now}`
  )

  loading.value = false

  // apiEndpoint.value = apiData.value
  // setTimeout(() => {
  //   showGraph.value = true
  // }, 500)
  // refGraphWrapper.value.style.setProperty(
  //   '--icon-url',
  //   `url(${oracleStore.getNetworkIconUrl(networkName.value)})`
  // )
  // loading.value = false
}

function openInfo() {
  opened.value = !opened.value
  newOraclesStore.editedOracle = props.oracle
}

onMounted(async () => {
  if (!apiData.value) await loadOracleData()
})
</script>

<style lang="scss" scoped>
.info {
  border-radius: 0 0 10px 10px;
  border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
  border-top: 0;
  background: var(--Block-bg, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  opacity: 0;
  display: grid;
  grid-template-rows: 0fr;
  transition:
    opacity 0.3s,
    grid-template-rows 0.3s;
  .opened & {
    opacity: 1;
    grid-template-rows: 1fr;
  }
}

.oracle {
  transition: opacity 0.3s;
  &.loading {
    pointer-events: none;
    opacity: 0.5;
  }
}

.inner {
  overflow: hidden;
  padding: 0 30px;
  transition: padding 0.3s;

  @media (max-width: 1023px) {
    padding: 0 15px;
  }

  .opened & {
    padding: 30px;

    @media (max-width: 1023px) {
      padding: 20px 15px;
    }
  }
}
</style>
