export default function (number, decimals = 20) {
  if (number === null || number === undefined) return ''
  if (number === 0) return '0'
  const numberStr = parseFloat(number).toFixed(decimals)

  if (parseFloat(number) > 0.0009) {
    const formattedNumber = parseFloat(number).toFixed(3)
    return parseFloat(formattedNumber).toString()
  }

  const match = /0\.0*([1-9])/.exec(numberStr)

  if (match) {
    const zerosCount = match[0].length - 3
    let significantPart = numberStr.slice(match.index + zerosCount + 2)
    significantPart = significantPart.slice(0, 2).replace(/0+$/, '')
    return `0.0<sub>${zerosCount}</sub>${significantPart}`
  } else {
    return numberStr
  }
}
