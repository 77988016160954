<template>
  <div class="inner-info" :class="{ 'read-mode': props.readMode }">
    <div class="subtitle">
      {{ texts.oracle_overview }}
    </div>

    <div class="box-row">
      <div class="inner-box">
        <div class="label">
          {{ texts.overview_labels[0].label }}
        </div>
        <div class="value">
          <img
            :src="getNetworkIconUrl(chainDetails?.name)"
            width="20"
            height="20"
            class="icon"
          />
          {{ chainDetails?.name }}
        </div>
      </div>
      <div class="inner-box">
        <div class="label">
          {{ texts.overview_labels[1].label }}
        </div>
        <div class="value">
          {{ shortenAddress(props.oracle.address) }}
          <a
            :href="generateExplorerLink(oracle.chainId, props.oracle.address)"
            target="_blank"
            rel="nofoloow noopener noreferrer"
            class="etherscan"
            v-html="EtherscanSvg"
          />
          <DeployerUiCopy :copy-value="props.oracle.address" />
        </div>
      </div>
      <div class="inner-box mobile-hide">
        <div class="label">
          {{ texts.overview_labels[2].label }}
        </div>
        <div class="value">
          {{ props.apiData?.Assets?.length }}
        </div>
      </div>
      <div v-if="!props.readMode" class="inner-box">
        <div class="label">
          {{ texts.overview_labels[3].label }}
        </div>
        <div class="value">
          <span v-html="gasLeft" />

          <img
            v-if="whitelabelStore?.config?.gas_icon"
            :src="whitelabelStore?.config?.gas_icon"
            width="20"
            height="20"
            class="icon"
            alt="gas left token icon"
          />
          <button
            type="button"
            class="btn grey small top-up"
            @click.prevent="openTopUp"
          >
            TOP UP
          </button>
        </div>
      </div>
    </div>

    <div v-if="props.readMode" class="large-mb40">
      <div class="subtitle">
        {{ textsPrice.price_feeds }}
      </div>
      <DeployerListOraclePriceFeeds
        :feeds="props.apiData?.Assets"
        :oracle="oracle"
        :read-mode="props.readMode"
      />
    </div>
    <div class="title-row mobile-hide">
      <div class="left">
        <div class="subtitle">
          {{ texts.oracle_activity }}
        </div>
        <button
          type="button"
          class="btn grey small"
          @click.prevent="openUpdatesModal"
        >
          <div class="btn__inner">
            <span v-html="UpdatesSvg" /> {{ texts.history_button_label }}
          </div>
        </button>
      </div>
      <div class="right">
        <button
          type="button"
          class="btn grey small"
          :class="{ active: showDays === 7 }"
          @click.prevent="showDays = 7"
        >
          <div class="btn__inner">7d</div>
        </button>
        <button
          type="button"
          class="btn grey small"
          :class="{ active: showDays === 30 }"
          @click.prevent="showDays = 30"
        >
          <div class="btn__inner">30d</div>
        </button>
      </div>
    </div>
    <div class="info-graph mobile-hide">
      <div v-if="!props.readMode" class="box-col">
        <div class="inner-box">
          <div class="label">
            {{ texts.activity_labels[0].label }}
          </div>
          <div class="value">
            {{ props.apiData?.AvgDailyTxs.toFixed(0) }}
          </div>
        </div>
        <div class="inner-box">
          <div class="label">
            {{ texts.activity_labels[1].label }}
          </div>
          <div class="value">
            {{ parseFloat(props.apiData?.AvgGasSpend.toFixed(2)) }}
            <img
              v-if="whitelabelStore?.config?.gas_icon"
              :src="whitelabelStore?.config?.gas_icon"
              width="22"
              height="22"
              alt="gas usage token icon"
            />
          </div>
        </div>
        <div class="inner-box">
          <div class="label">
            {{ texts.activity_labels[2].label }}
          </div>
          <div class="value">
            {{ (props.apiData?.Count / 1000).toFixed(2) }} K
          </div>
        </div>
        <div class="inner-box">
          <div class="label">
            {{ texts.activity_labels[3].label }}
          </div>
          <div class="value">
            <span
              v-html="formatLowNumbers(gasConvert(props.apiData?.GasSpend))"
            />

            <img
              v-if="whitelabelStore?.config?.gas_icon"
              :src="whitelabelStore?.config?.gas_icon"
              width="22"
              height="22"
              alt="gas pend token icon"
            />
          </div>
        </div>
      </div>
      <div>
        <DeployerListOracleGraph
          :api-data="props.apiData"
          :show-days="showDays"
          :read-mode="props.readMode"
        />
      </div>
    </div>
    <div v-if="!props.readMode">
      <div class="subtitle">
        {{ textsPrice.price_feeds }}
      </div>
      <DeployerListOraclePriceFeeds
        :feeds="props.apiData?.Assets"
        :oracle="oracle"
        :read-mode="props.readMode"
      />
    </div>
  </div>
</template>

<script setup>
import UpdatesSvg from '~/assets/icons/updates.svg?raw'
import EtherscanSvg from '~/assets/icons/etherscan.svg?raw'
import { getChains } from '@wagmi/core'
import { switchChain } from '@wagmi/core'
import { toHex } from 'viem'

const config = useRuntimeConfig()

const modalStore = useModalStore()
const textsStore = useTextsStore()
const newWalletStore = useNewWalletStore()
const whitelabelStore = useWhitelabelStore()

const texts = computed(() => {
  return textsStore.list.oracle_info
})

const textsPrice = computed(() => {
  return textsStore.list.price_feeds
})

const props = defineProps({
  oracle: Object,
  apiData: Object,
  readMode: {
    type: Boolean,
    default: false
  }
})

function generateExplorerLink(chainId, contractAddress) {
  const explorers = {
    1: 'https://etherscan.io',
    3: 'https://ropsten.etherscan.io',
    4: 'https://rinkeby.etherscan.io',
    5: 'https://goerli.etherscan.io',
    11155111: 'https://sepolia.etherscan.io',
    42: 'https://kovan.etherscan.io',
    56: 'https://bscscan.com',
    97: 'https://testnet.bscscan.com',
    137: 'https://polygonscan.com',
    80001: 'https://mumbai.polygonscan.com',
    42161: 'https://arbiscan.io',
    421613: 'https://goerli.arbiscan.io',
    10: 'https://optimistic.etherscan.io',
    420: 'https://goerli-optimism.etherscan.io',
    43114: 'https://snowtrace.io',
    43113: 'https://testnet.snowtrace.io',
    250: 'https://ftmscan.com',
    4002: 'https://testnet.ftmscan.com',
    100: 'https://gnosisscan.io',
    9001: 'https://evmos.escan.live'
  }

  const baseUrl = explorers[chainId] || 'https://etherscan.io'
  return `${baseUrl}/address/${contractAddress}`
}

async function getChainDetails() {
  const chains = getChains(newWalletStore.wagmiConfig)

  return chains.find((c) => c.id === Number(props.oracle.chainId))
}

const chainDetails = await getChainDetails()

const showDays = ref(30)

const gasLeft = computed(() => {
  if (!props.apiData) return '-'
  return formatLowNumbers(gasConvert(props.apiData.GasRemaining))
})

const oracle = ref({
  address: props.oracle.address,
  chainId: props.oracle.chainId,
  draft: props.oracle.draft
})

const openUpdatesModal = () => {
  modalStore.show = true
  modalStore.title = textsStore.list.updates_modal.title
  modalStore.text = `${textsStore.list.updates_modal.text} <a href="${config.public.oracleApiBaseURL}/stats?address=${props.oracle.address}&chainID=${props.oracle.chainId}&page=1">See all</a>`
  modalStore.canClose = true
  modalStore.type = 'updates'
  modalStore.oracle = {
    address: props.oracle.address,
    chainId: props.oracle.chainId,
    draft: props.oracle.draft,

    symbol: null,
    readMode: props.readMode
  }
}

const openTopUp = async () => {
  const chainID = Number(props.oracle.chainId)
  try {
    // Call switchChain and await its completion
    await switchChain(newWalletStore.wagmiConfig, {
      addEthereumChainParameter: {
        chainId: toHex(chainID)
      },
      chainId: chainID
    })

    console.log('Successfully switched to chain')
  } catch (error) {
    console.error('Error switching chain:', error)
  }
  console.log('props.oracle.chainId', props.oracle.chainId)
  modalStore.show = true
  modalStore.title = textsStore.list.top_up.title
  modalStore.text = textsStore.list.top_up.text
  modalStore.type = 'top-up'
}
</script>

<style lang="scss" scoped>
.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .left {
    gap: 20px;
  }

  .right {
    gap: 10px;
  }

  :deep(path) {
    stroke: var(--Text, #000);
  }
}

.value {
  line-height: 100%;
  display: flex;
  align-items: center;
  :deep(svg),
  :deep(img) {
    display: block;
    position: relative;
    top: -2px;
  }

  :deep(path) {
    stroke: var(--Text, #000);
  }
}

.box-row {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px;
  margin-bottom: 40px;

  .read-mode & {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    border-radius: 10px;
    grid-gap: 30px;
    border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
    background: var(--Box-bg, rgba(255, 255, 255, 0));
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.inner-box {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
  background: var(--Box-bg, rgba(255, 255, 255, 0));
  @media (max-width: 1024px) {
    border: 0;
    padding: 0;
  }
  .label {
    margin-bottom: 10px;
  }
}

.info-graph {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 3fr;
  margin-bottom: 40px;

  .read-mode & {
    grid-template-columns: 1fr;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
}

.box-col {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.top-up {
  margin-top: -2px;
}

.mobile-hide {
  @media (max-width: 1024px) {
    display: none;
  }
}
</style>
