<template>
  <div class="row" :class="{ loading }">
    <div class="col active-large col--name">
      <div class="label">{{ texts[0].title }}</div>
      <div class="value loading-value">&nbsp;</div>
    </div>
    <div class="col col--status">
      <div class="label">{{ texts[1].title }}</div>
      <div class="value loading-value">&nbsp;</div>
    </div>
    <div class="col active-hide">
      <div class="label">{{ texts[2].title }}s</div>
      <div class="value loading-value">&nbsp;</div>
    </div>
    <div class="col active-hide">
      <div class="label">{{ texts[3].title }}</div>
      <div class="value loading-value"></div>
    </div>
    <div class="col active-hide">
      <div class="label">{{ texts[4].title }}</div>
      <div class="value loading-value"></div>
    </div>
    <div class="col right-aligned col--update">
      <div class="inner">
        <div class="label">{{ texts[5].title }}</div>
        <div class="value loading-value">&nbsp;</div>
      </div>
    </div>
    <div class="col actions"></div>
  </div>
</template>

<script setup>
const textsStore = useTextsStore()
const texts = computed(() => {
  return textsStore.list.listing.list_column_titles
})
const loading = ref(true)
</script>

<style lang="scss" scoped>
.row {
  border-radius: 10px;
  border: 1px solid var(--Block-stroke, rgba(0, 0, 0, 0.1));
  background: var(--Block-bg, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 20px 30px;
  display: grid;
  gap: 10px;
  grid-template-columns: 22% 10.5% 10.5% 10.5% 10.5% auto 0%;
  transition:
    grid-template-columns 0.3s,
    background-color 0.3s,
    border-radius 0.3s;

  pointer-events: none;

  @media (max-width: 1023px) {
    grid-template-columns: 25% 25% 25% auto;
  }

  .col {
    transition: opacity 0.3s;
    @media (max-width: 1023px) {
      display: none;
    }
  }
  .right-aligned {
    @media (min-width: 1024px) {
      display: flex;
      justify-content: flex-end;
    }

    .value {
      @media (max-width: 1023px) {
        text-align: right;
      }
    }
  }

  .inner {
    min-width: 145px;
    @media (max-width: 1023px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
  }
}

.label {
  margin-bottom: 15px;

  @media (max-width: 1024px) {
    display: none;
  }
}

.loading-value {
  position: relative;
  .loading & {
    * {
      opacity: 0;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 80%;
      max-width: 120px;
      border-radius: 3px;
      z-index: 9;
      background-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.15) 0%,
        rgba(0, 0, 0, 0.05) 100%
      );
      // animation: gradientAnim 5.5s linear infinite;
    }
  }
}

@keyframes gradientAnim {
  0% {
    left: 0;
    width: 0;
  }
  35% {
    left: 0;
    width: 50%;
  }
  65% {
    left: 50%;
    width: 50%;
  }
  100% {
    left: 100%;
    width: 0;
  }
}
</style>
