<template>
  <div class="info-wrapper">
    <div class="list">
      <DeployerListOracleRowEmpty />
      <DeployerListOracleRowEmpty />
      <DeployerListOracleRowEmpty />
      <DeployerListOracleRowEmpty />
    </div>
    <div class="message">
      <div class="text" v-html="texts.no_oracles"></div>

      <NuxtLink to="/deploy/" class="btn cta add-oracle-btn"
        ><span v-html="AddOracleSvg"></span> {{ texts.no_oracles_button }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup>
import AddOracleSvg from '~/layers/base/assets/icons/add-oracle.svg?raw'
const textsStore = useTextsStore()
const texts = computed(() => {
  return textsStore.list.listing
})
</script>

<style lang="scss" scoped>
.info-wrapper {
  position: relative;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.05);

  backdrop-filter: blur(2.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 40px;
  z-index: 9;
  .text {
    font-weight: 500;
    font-size: 1.8rem;
  }
}

.add-oracle-btn {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  text-transform: none;
  font-family: var(--font-std);

  :deep(svg) {
    position: relative;
    top: -1px;
  }
}
</style>
