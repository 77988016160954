export default function (timestamp) {
  const currentTime = new Date()
  const targetTime = new Date(timestamp)

  const diffInSeconds = Math.floor((currentTime - targetTime) / 1000)
  const diffInMinutes = Math.floor(diffInSeconds / 60)

  const padZero = (num) => (num < 10 ? '0' : '') + num

  if (diffInMinutes < 60) {
    const minutesLabel = diffInMinutes === 1 ? 'minute' : 'minutes'
    const hours = padZero(targetTime.getHours())
    const minutes = padZero(targetTime.getMinutes())
    const seconds = padZero(targetTime.getSeconds())

    return `${diffInMinutes} ${minutesLabel} ago ${hours}:${minutes}:${seconds}`
  } else {
    const day = padZero(targetTime.getDate())
    const month = padZero(targetTime.getMonth() + 1)
    const year = targetTime.getFullYear()
    const hours = padZero(targetTime.getHours())
    const minutes = padZero(targetTime.getMinutes())
    const seconds = padZero(targetTime.getSeconds())

    return `${hours}:${minutes}:${seconds} ${day}.${month}.${year}`
  }
}
