<template>
  <div>
    <button
      type="button"
      class="page-title page-title--oracles"
      v-if="newOraclesStore.selectedOraclesTab === 'deleted'"
      @click.prevent="newOraclesStore.selectedOraclesTab = 'user'"
    >
      <span v-html="ChevronLeftSvg" class="arrow-back"></span>
      {{ oraclesTitle }}
    </button>
    <div class="page-title page-title--oracles" v-else>{{ oraclesTitle }}</div>
    <div class="switch">
      <div class="switch__left">
        <button
          v-if="showSwitch"
          type="button"
          class="switch-btn"
          :class="{
            active: newOraclesStore.selectedOraclesTab === 'ecosystem'
          }"
          @click.prevent="newOraclesStore.selectedOraclesTab = 'ecosystem'"
        >
          <div v-html="EcosystemOraclesSvg" />
          {{ texts.ecosystem_oracles }}
          <span class="count">{{ ecosystemOracles.length }}</span>
        </button>
        <button
          type="button"
          class="switch-btn"
          :class="{ active: newOraclesStore.selectedOraclesTab === 'user' }"
          @click.prevent="newOraclesStore.selectedOraclesTab = 'user'"
        >
          <div v-html="UserOraclesSvg" />
          {{ texts.user_oracles }}
          <span class="count">{{ userOraclesMain.length }}</span>
        </button>
      </div>
      <div class="switch__right">
        <button
          type="button"
          class="switch-btn"
          :class="{ active: newOraclesStore.selectedOraclesTab === 'deleted' }"
          @click.prevent="newOraclesStore.selectedOraclesTab = 'deleted'"
        >
          <div v-html="DeletedOraclesSvg" />
          {{ texts.deleted_oracles }}
        </button>
      </div>
    </div>

    <div
      v-show="newOraclesStore.selectedOraclesTab === 'ecosystem'"
      v-if="ecosystemOracles.length > 0"
      class="list"
    >
      <!-- TODO: Kamil - please set read-mode on true if user should see simpliefied version -->
      <DeployerListOracle
        v-for="oracle in ecosystemOracles"
        :key="oracle.address + oracle.chainId"
        :oracle="oracle"
        :read-mode="true"
      />
    </div>

    <div v-show="newOraclesStore.selectedOraclesTab === 'user'" class="list">
      <DeployerListNoOracles v-if="noOracles" />
      <template v-if="userOraclesMain?.length">
        <!-- <DeployerListOracleRowDraft :oracle="userOraclesMain[0]" /> -->
        <DeployerListOracle
          v-for="oracle in userOraclesMain"
          :key="oracle.address + oracle.chainId"
          :oracle="oracle"
        />
      </template>
      <button
        type="button"
        class="mobile-show-deleted"
        v-if="userOraclesDeleted.length > 0"
        @click.prevent="showDeletedMobile"
      >
        <div v-html="DeletedOraclesSvg" />
        <div class="btn-label" v-html="texts.show_deleted_oracles"></div>
        <div class="counter">{{ userOraclesDeleted.length }}</div>
      </button>
    </div>
    <div v-show="newOraclesStore.selectedOraclesTab === 'deleted'" class="list">
      <DeployerListOracle
        v-for="oracle in userOraclesDeleted"
        :key="oracle.address + oracle.chainId"
        :oracle="oracle"
      />
    </div>
    <hr
      class="mobile-line"
      :class="{ 'no-button': userOraclesDeleted.length === 0 }"
    />
    <DeployerListDocuBar class="mt20" />
  </div>
</template>

<script setup>
import EcosystemOraclesSvg from '~/layers/base/assets/icons/ecosystem-oracles.svg?raw'
import UserOraclesSvg from '~/layers/base/assets/icons/user-oracles.svg?raw'
import DeletedOraclesSvg from '~/layers/base/assets/icons/delete.svg?raw'
import ChevronLeftSvg from '~/layers/base/assets/icons/chevron-left.svg?raw'

const config = useRuntimeConfig()

// //TODO: Kamil - fetch users/ecosystem oracles (maybe it would be better to save those to store?)

const showSwitch = ref(true)
const whitelabelStore = useWhitelabelStore()
const newOraclesStore = useNewOraclesStore()
const textsStore = useTextsStore()

const texts = computed(() => {
  return textsStore.list.listing
})

if (!whitelabelStore.config.ecosystem_oracles) {
  showSwitch.value = false
  newOraclesStore.selectedOraclesTab = 'user'
}

const oraclesTitle = computed(() => {
  if (newOraclesStore.selectedOraclesTab === 'ecosystem') {
    return texts.value.ecosystem_oracles
  } else if (newOraclesStore.selectedOraclesTab === 'user') {
    return texts.value.user_oracles
  } else if (newOraclesStore.selectedOraclesTab === 'deleted') {
    return texts.value.deleted_oracles
  }
})

const ecosystemOracles = computed(() => {
  return [
    {
      address: '0x11E405e4432c615315C624159A42584A03DccAdF',
      chainId: 11155111,
      oracleId: 'Test Oracle Lending',
      status: 'Active',
      lastUpdate: '',
      network: 'Mainnet',
      gasLeft: '',
      priceFeeds: ''
    }
  ]
})

const userOracles = computed(() => {
  return newOraclesStore.oracles
    ? newOraclesStore.oracles
        .map((oracle) => ({
          address: oracle.Address,
          feederAddress: oracle.FeederAddress,
          chainId: oracle.ChainID,
          oracleId: oracle.FeederID,
          active: oracle.Active,
          expired: oracle.Expired,
          deleted: oracle.Deleted,
          lastUpdate: oracle.LastUpdate,
          lastOracleUpdate: oracle.LastOracleUpdate,
          network: oracle.Network,
          gasLeft: oracle.GasLeft,
          priceFeeds: oracle.PriceFeeds,
          priceFeeds: oracle.PriceFeeds,
          draft: oracle.Draft
        }))
        .sort((a, b) => (a.lastOracleUpdate < b.lastOracleUpdate ? 1 : -1))
    : []
})

const userOraclesMain = computed(() => {
  return userOracles.value.filter((oracle) => !oracle.deleted)
})

const userOraclesDeleted = computed(() => {
  return userOracles.value.filter((oracle) => oracle.deleted)
})

const noOracles = computed(() => {
  return !userOraclesMain.value.length
})

const showDeletedMobile = () => {
  newOraclesStore.selectedOraclesTab = 'deleted'

  window.scrollTo(0, 0)
}
</script>

<style lang="scss" scoped>
.switch {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--Block-stroke);
  justify-content: space-between;
  align-items: flex-end;

  &__left {
    display: flex;
    gap: 20px;
  }

  @media (max-width: 1023px) {
    display: none;
  }
}

.switch-btn {
  padding: 10px;
  font-size: 1.5rem;
  color: var(--Text, #000);
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1.5px solid transparent;
  @media (max-width: 560px) {
    font-size: 1.3rem;
    padding: 10px 5px;
    gap: 5px;
    border-bottom: 1.5px solid transparent;
  }

  :deep(svg) {
    display: block;
    position: relative;
    top: -1px;
    @media (max-width: 560px) {
      width: 15px;
      height: 15px;
    }

    path {
      stroke: var(--Text, #000);
    }
  }

  .count {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 21px;
    border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
    background: var(--bubble-10, rgba(0, 0, 0, 0.1));
    line-height: 100%;

    @media (max-width: 560px) {
      width: 18px;
      height: 18px;
      font-size: 1rem;
      border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
      background: var(--CTA, #3d6ef6);
      color: var(--CTA-text, #fff);
      position: relative;
      top: -2px;
    }
  }

  &.active {
    font-weight: 500;
    letter-spacing: normal;
    border-bottom: 1.5px solid var(--Text, #000);
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.page-title--oracles {
  font-weight: 700;
  font-size: 2.2rem;

  :deep(path) {
    stroke: var(--Text, #000);
  }

  @media (min-width: 1024px) {
    display: none;
  }
}

.mobile-show-deleted {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 10px;
  font-weight: 500;

  .btn-label {
    flex: 1 1 0;
  }

  .counter {
    color: var(--CTA);
  }

  :deep(path) {
    stroke: var(--Text-50, #000);
  }

  @media (min-width: 1024px) {
    display: none;
  }
}

.mobile-line {
  border: 0;
  border-top: 1px solid var(--bubble-10);
  margin-top: 0;
  margin-bottom: 30px;

  &.no-button {
    margin-top: 30px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
}
</style>
