<template>
  <div
    class="row"
    :class="{ opened, loading, deleted: props.oracle.deleted }"
    @click.prevent="openInfo"
  >
    <div class="col active-large col--name">
      <div class="label">
        {{ texts[0].title }}
      </div>
      <div class="value">
        {{ props.oracle.oracleId }}
      </div>
    </div>
    <div class="col col--status">
      <div class="label">
        {{ texts[1].title }}
      </div>
      <div class="value" v-html="showStatus" />
    </div>
    <div class="col active-hide">
      <div class="label">
        {{ texts[2].title }}
      </div>
      <div class="value loading-value">
        {{ props.apiData?.Assets?.length }}
      </div>
    </div>
    <div class="col active-hide">
      <div class="label">
        {{ texts[3].title }}
      </div>
      <div class="value">
        <!--TODO: Kamil change to real network -->
        <img
          :src="getNetworkIconUrl(chainDetails?.name)"
          width="20"
          height="20"
          class="icon"
        />{{ props.oracle.network }}
      </div>
    </div>
    <div class="col active-hide">
      <div v-if="!props.readMode" class="label">
        {{ texts[4].title }}
      </div>
      <div v-if="!props.readMode" class="value loading-value">
        <span v-html="gasLeft" />
        <img
          v-if="whitelabelStore?.config?.gas_icon"
          :src="whitelabelStore?.config?.gas_icon"
          width="20"
          height="20"
          class="icon"
        />
      </div>
    </div>
    <div class="col right-aligned col--update">
      <div class="inner">
        <div class="label">
          {{ texts[5].title }}
        </div>
        <div class="value loading-value">
          <div class="last-update-val">{{ lastUpdate }}</div>
          <div class="status-val">{{ showMobileStatus }}</div>
        </div>
      </div>
    </div>
    <div class="col actions">
      <div class="options">
        <DeployerUiDropdownNav v-if="!props.readMode">
          <DeployerUiDropdownNavButton
            :icon-svg="NetworkSvg"
            label="Migrate to mainnet"
            @click.prevent="migrateNetwork"
          />
          <!-- <hr />
          <DeployerUiDropdownNavButton
            :iconSvg="TelegramSvg"
            label="Set up Telegram alerts"
            @click.prevent="setTelegram"
          /> -->
          <hr />
          <DeployerUiDropdownNavButton
            :icon-svg="RenameSvg"
            :label="renameOracleButtonText"
            @click.stop="handleRenameOracleClick"
          />
          <label v-if="renameOracleVisible" class="field rename-oracle">
            <input
              v-model="renameOracleValue"
              type="text"
              class="field__field"
              :placeholder="oracle.oracleId"
              @click.stop
            />
          </label>
          <hr />
          <DeployerUiDropdownNavButton
            v-if="props.oracle.active"
            :icon-svg="PauseSvg"
            label="Pause"
            @click.prevent="pauseOracle"
          />
          <DeployerUiDropdownNavButton
            v-if="!props.oracle.active"
            :icon-svg="RestartSvg"
            label="Restart"
            @click.prevent="restartOracle"
          />
          <DeployerUiDropdownNavButton
            :icon-svg="EditSvg"
            label="Edit"
            @click.prevent="editOracle"
          />
          <DeployerUiDropdownNavButton
            :icon-svg="DeleteSvg"
            label="Delete"
            @click.prevent="deleteOracle"
          />
        </DeployerUiDropdownNav>
      </div>
      <button type="button" class="opener" v-html="ChevronSvg" />
    </div>
  </div>
</template>

<script setup>
import ChevronSvg from '~/assets/icons/chevron-up.svg?raw'
import NetworkSvg from '~/assets/icons/network.svg?raw'
import PauseSvg from '~/assets/icons/pause.svg?raw'
import EditSvg from '~/assets/icons/edit.svg?raw'
import DeleteSvg from '~/assets/icons/delete.svg?raw'
import RestartSvg from '~/assets/icons/restart.svg?raw'
import RenameSvg from '~/assets/icons/rename.svg?raw'
import { getChains } from '@wagmi/core'

const props = defineProps({
  oracle: Object,
  apiData: Object,
  loading: Boolean,
  readMode: {
    type: Boolean,
    default: false
  }
})

const newWalletStore = useNewWalletStore()
const newOraclesStore = useNewOraclesStore()
const whitelabelStore = useWhitelabelStore()
const accountStore = useAccountStore()
const config = useRuntimeConfig()

const textsStore = useTextsStore()
const texts = computed(() => {
  return textsStore.list.listing.list_column_titles
})

const emits = defineEmits(['openInfo'])

const opened = ref(false)

const gasLeft = computed(() => {
  if (!props.apiData) return '-'

  return formatLowNumbers(gasConvert(props.apiData.GasRemaining))
})

async function getChainDetails() {
  const chains = getChains(newWalletStore.wagmiConfig)

  return chains.find((c) => c.id === Number(props.oracle.chainId))
}

const chainDetails = await getChainDetails()

const lastUpdate = computed(() => {
  if (!props.apiData) return '-'
  if (!props.apiData.LastOracleUpdate) return '-'
  return formatLastUpdate(props.apiData.LastOracleUpdate)
})

const isLowGas = computed(() => {
  if (!props.apiData) return false
  if (props.apiData.GasRemaining <= props.apiData.AvgGasSpend * 7) {
    return true
  }
  return false
})

const isOutOfGas = computed(() => {
  if (!props.apiData) return false
  if (props.apiData.GasRemaining <= props.apiData.AvgGasSpend * 3) {
    return true
  }
  return false
})

const showStatus = computed(() => {
  if (props.oracle.expired) {
    return '<div class="status-dot status-dot--purple"></div> Expired'
  } else if (props.oracle.deleted) {
    return '<div class="status-dot status-dot--purple"></div> Deleted'
  } else if (props.oracle.draft) {
    return '<div class="status-dot status-dot--purple"></div> Draft'
  } else if (!props.oracle.active) {
    return '<div class="status-dot status-dot--pause"></div> Paused'
  } else if (isOutOfGas.value) {
    return '<div class="status-dot status-dot--out-of-gas"></div> Out of gas'
  } else if (isLowGas.value) {
    return '<div class="status-dot status-dot--low-gas"></div> Low gas'
  } else if (props.oracle.active) {
    return '<div class="status-dot status-dot--active"></div> Active'
  }
})

const showMobileStatus = computed(() => {
  if (props.oracle.expired) {
    return 'Expired'
  } else if (props.oracle.deleted) {
    return 'Deleted'
  }

  return ''
})

const openInfo = () => {
  emits('openInfo')
  opened.value = !opened.value
}

//TODO: Kamil - all those function below (except Telegram)

const migrateNetwork = () => {
  console.log('Migrate network')
}

const setTelegram = () => {
  // console.log('Set Telegram')
}

const renameOracleVisible = ref(false)
const renameOracleButtonText = computed(() =>
  renameOracleValue.value ? 'Save' : 'Rename oracle'
)
const renameOracleValue = ref('')

async function handleRenameOracleClick() {
  if (!renameOracleVisible.value) renameOracleVisible.value = true
  else {
    renameOracleVisible.value = false
    renameOracleValue.value = ''
    await renameOracle()
  }
}

async function renameOracle() {
  const oracle = newOraclesStore.oracles.find(
    (o) => o.Address === props.oracle.address
  )

  console.log('oracle', oracle)

  if (!oracle) {
    console.error('Error finding oracle')
    return
  }

  await editOracle(oracle)
}

const editOracle = async (oracle) => {
  const signedData = await newWalletStore.signData(
    newWalletStore.account.chainId,
    config.public.addressVerificationMessage,
    oracle.Address
  )

  if (!signedData) {
    console.error('Error signing data - address verification')
  }

  await useEditOracle(oracle, signedData)
}

const pauseOracle = async () => {
  const chainId = newWalletStore.account.chainId
  const oracleAddress = props.oracle.address

  const signedData = await newWalletStore.signData(
    chainId,
    config.public.pauseOracleMessage,
    oracleAddress
  )

  if (!signedData) {
    console.error('error on pauseOracle, sign fail')
    return
  }

  const requestOptions = {
    method: 'PATCH',
    redirect: 'follow',
    headers: {
      Authorization: `Bearer ${signedData}`,
      'Content-Type': 'multipart/form-data'
    },
    'Content-Type': 'multipart/form-data'
  }

  try {
    await fetch(
      `${config.public.oracleApiBaseURL}/oraclebuilder-prod/pause?creator=${
        newWalletStore.account.address
      }&oracleChainID=${parseInt(
        props.apiData.Chain
      )}&chainID=${chainId}&oracleaddress=${oracleAddress}`,
      requestOptions
    )

    await accountStore.viewDashboard()
  } catch (err) {
    console.error('Error on pause oracle', err)
  }
}

const restartOracle = async () => {
  const chainId = newWalletStore.account.chainId
  const oracleAddress = props.oracle.address

  console.log(
    'sign data',
    chainId,
    config.public.restartOracleMessage,
    oracleAddress
  )

  const signedData = await newWalletStore.signData(
    chainId,
    config.public.restartOracleMessage,
    oracleAddress
  )

  if (!signedData) {
    console.error('error on restartOracle, sign fail')
    return
  }

  const requestOptions = {
    method: 'PATCH',
    redirect: 'follow',
    headers: {
      Authorization: `Bearer ${signedData}`
    }
  }

  try {
    await fetch(
      `${config.public.oracleApiBaseURL}/oraclebuilder-prod/restart?creator=${
        newWalletStore.account.address
      }&oracleChainID=${parseInt(
        props.apiData.Chain
      )}&chainID=${chainId}&oracleaddress=${oracleAddress}`,
      requestOptions
    )

    await accountStore.viewDashboard()
  } catch (err) {
    console.error('Error on restart oracle', err)
  }
}

// const editOracle = () => {
//   console.log('Edit oracle')
// }

const deleteOracle = async () => {
  const oracleAddress = props.oracle.address
  const chainId = newWalletStore.account.chainId

  const signedData = await newWalletStore.signData(
    chainId,
    config.public.deleteOracleMessage,
    oracleAddress
  )

  if (!signedData) {
    console.error('error on deleteOracle, sign fail')
    return
  }

  const formData = new FormData()
  formData.append('feederID', props.oracle.oracleId)

  const requestOptions = {
    method: 'DELETE',
    redirect: 'follow',
    body: formData,
    headers: {
      Authorization: `Bearer ${signedData}`
    }
  }

  try {
    await fetch(
      `${config.public.oracleApiBaseURL}/oraclebuilder-prod/delete?creator=${newWalletStore.account.address}&chainID=${chainId}&oracleChainID=${props.oracle.chainId}&oracleaddress=${oracleAddress}`,
      requestOptions
    )
  } catch (err) {
    console.error('Error on delete oracle', err)
  }

  await accountStore.viewDashboard()
}
</script>

<style lang="scss" scoped>
.row {
  border-radius: 10px;
  border: 1px solid var(--Block-stroke, rgba(0, 0, 0, 0.1));
  background: var(--Block-bg, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 20px 30px;
  display: grid;
  gap: 10px;
  grid-template-columns: 22% 10.5% 10.5% 10.5% 10.5% auto 0%;
  transition:
    grid-template-columns 0.3s,
    background-color 0.3s,
    border-radius 0.3s;
  cursor: pointer;

  @media (max-width: 1023px) {
    grid-template-columns: 25px auto 80px;
    grid-template-rows: 1fr auto;
    grid-template-areas: 'A B C' 'A D C';
    padding: 15px;
  }

  :deep(.status-dot) {
    width: 9px;
    height: 9px;
    border-radius: 100%;
    position: relative;
    top: -1px;
  }

  :deep(.status-dot--active) {
    background: #65c728;
  }

  :deep(.status-dot--pause) {
    background: #bebebe;
  }

  :deep(.status-dot--purple) {
    background: #b373e5;
  }

  :deep(.status-dot--low-gas) {
    width: 15px;
    height: 15px;
    background: url(~/layers/base/assets/icons/exclamation.svg) no-repeat center
      center #ffc800;
  }
  :deep(.status-dot--out-of-gas) {
    width: 15px;
    height: 15px;
    background: url(~/layers/base/assets/icons/exclamation.svg) no-repeat center
      center #e31a1c;
  }

  .col {
    transition: opacity 0.3s;
    @media (max-width: 1023px) {
      display: none;

      &--name,
      &--update,
      &--status,
      &.actions {
        display: flex;
      }

      &--status {
        grid-area: A;
        display: flex;
        align-items: center;
        justify-content: center;
        .value {
          font-size: 0rem;
        }
      }

      &--name {
        font-size: 1.5rem;
        font-weight: 500;
        grid-area: B;
      }

      &--update {
        opacity: 0.6;
        grid-area: D;
        .value {
          font-size: 1.2rem;
        }

        & {
          letter-spacing: 0.12px;
        }
      }

      &.actions {
        grid-area: C;
      }
    }
  }

  .actions {
    opacity: 0;
    padding-left: 0;
    transition:
      opacity 0.3s,
      padding-left 0.3s;
    @media (max-width: 1023px) {
      opacity: 1;
    }
  }

  .active-hide {
    @media (max-width: 1023px) {
    }
  }

  &:hover {
    background: var(--Banner-5, rgba(0, 0, 0, 0.05));
    @media (min-width: 1024px) {
      grid-template-columns: 22% 10.5% 10.5% 10.5% 10.5% auto 100px;
      .actions {
        opacity: 1;
        padding-left: 20px;
      }
    }
  }
  &.opened {
    background: var(--Banner-5, rgba(0, 0, 0, 0.05));

    @media (min-width: 1024px) {
      grid-template-columns: 29% 10% 8% 8% 8% auto 100px;
      .active-hide {
        opacity: 0;
      }
      .active-hide {
        opacity: 0;
      }

      .actions {
        opacity: 1;
        padding-left: 20px;
      }

      .active-large {
        .value {
          font-size: 2rem;
        }
      }
    }
  }

  &.opened {
    border-radius: 10px 10px 0 0;
  }

  .right-aligned {
    @media (min-width: 1024px) {
      display: flex;
      justify-content: flex-end;
    }

    .value {
      @media (max-width: 1023px) {
        text-align: right;
      }
    }
  }

  .inner {
    min-width: 145px;
    @media (max-width: 1023px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
  }
}

.label {
  margin-bottom: 15px;

  @media (max-width: 1024px) {
    display: none;
  }
}

.value {
  transition: font-size 0.3s;
  overflow: hidden;
}

.options {
  width: 30px;
  height: 30px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.opener {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  transition: transform 0.3s;
  border-radius: 5px;

  :deep(path) {
    stroke: var(--Text);
  }

  &:hover {
    background: var(--Banner-5, rgba(255, 255, 255, 0.05));
  }

  .opened & {
    transform: rotate(0deg);
  }
}

.loading-value {
  position: relative;
  .loading & {
    * {
      opacity: 0;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: 3px;
      z-index: 9;
      background-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.15) 0%,
        rgba(0, 0, 0, 0.05) 100%
      );
      animation: gradientAnim 1.5s linear infinite;
    }
  }
}

.last-update-val {
  @media (max-width: 1023px) {
    .deleted & {
      display: none;
    }
  }
}

.status-val {
  display: none;

  @media (max-width: 1023px) {
    .deleted & {
      display: block;
    }
  }
}

@keyframes gradientAnim {
  0% {
    left: 0;
    width: 0;
  }
  35% {
    left: 0;
    width: 50%;
  }
  65% {
    left: 50%;
    width: 50%;
  }
  100% {
    left: 100%;
    width: 0;
  }
}

.field {
  display: block;
  width: 100%;

  &.rename-oracle {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__label {
    font-size: 1.4rem;
    opacity: 0.5;
    margin-bottom: 5px;
  }

  &__field {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background: var(--Banner-5, rgba(0, 0, 0, 0.05));
    font-weight: 500;
    border: 0;
    padding-inline: 20px;
    color: var(--Text, #000);
    font-size: 1.5rem;
  }
}
</style>
