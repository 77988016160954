<template>
  <div class="graph-wrapper">
    <div class="graph-row">
      <div class="title" v-if="!props.readMode">{{ texts[0].label }}</div>
      <div></div>
      <div class="legend">
        <div class="legend-gas" v-if="!props.readMode">
          {{ texts[1].label }}
        </div>
        <div class="legend-txs">{{ texts[2].label }}</div>
      </div>
    </div>
    <div class="graph">
      <ClientOnly>
        <apexchart
          height="350"
          width="100%"
          type="line"
          :options="options"
          :series="series"
          ref="refChart"
        ></apexchart>
        <!-- <div class="graph-loading" v-else></div> -->
      </ClientOnly>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  apiData: Object,
  showDays: Number,
  readMode: {
    type: Boolean,
    default: false
  }
})

const textsStore = useTextsStore()
const texts = computed(() => {
  return textsStore.list.oracle_info.graph_labels
})

const showDays = ref(30)

const days = ref([])

const generateDays = (daysToShow) => {
  let dates = []
  let today = new Date()

  for (let i = 0; i < daysToShow; i++) {
    let date = new Date(today)
    date.setDate(date.getDate() - i)
    let dateString = date.toISOString().split('T')[0] + 'T00:00:00Z'
    dates.push(dateString)
  }

  return dates
}

days.value = generateDays(props.showDays)

const refChart = ref(null)
const showGraph = ref(false)

const updateSeriesData = computed(() => {
  let dates = []
  let today = new Date()

  for (let i = 0; i < days.value.length; i++) {
    let date = new Date(today)
    let value = 0
    date.setDate(date.getDate() - i)
    let dateString = date.toISOString().split('T')[0] + 'T00:00:00Z'
    props.apiData?.DayWiseUpdates?.find((item) => {
      if (item.Day === dateString) {
        value = item.UpdateCount
      }
    })
    dates.push(value)
  }

  return dates
})

const gasUsedData = computed(() => {
  if (props.readMode) {
    return []
  }

  let dates = []
  let today = new Date()

  for (let i = 0; i < days.value.length; i++) {
    let date = new Date(today)
    let value = 0
    date.setDate(date.getDate() - i)
    let dateString = date.toISOString().split('T')[0] + 'T00:00:00Z'
    props.apiData?.DayWiseUpdates?.find((item) => {
      if (item.Day === dateString) {
        value = item.GasUsed / 10 ** 18
      }
    })
    dates.push(value)
  }

  return dates
})

const series = computed(() => {
  return [
    {
      name: 'Gas Used',
      type: 'line',
      data: gasUsedData.value
    },
    {
      name: 'Updates',
      type: 'column',
      data: updateSeriesData.value
    }
  ]
})

const colorMode = useCookie('colorMode')

const barColor = computed(() => {
  if (colorMode.value === 'dark') {
    return 'rgba(92, 48, 219, 1)'
  } else {
    return 'rgba(92, 48, 219, .2)'
  }
})

const lineColor = computed(() => {
  if (colorMode.value === 'dark') {
    return '#C8FDD3'
  } else {
    return '#3D6EF6'
  }
})
const textColor = computed(() => {
  if (colorMode.value === 'dark') {
    return 'rgba(255,255,255,0.6)'
  } else {
    return 'rgba(0,0,0,0.8)'
  }
})

const gridColor = computed(() => {
  if (colorMode.value === 'dark') {
    return 'rgba(255,255,255,0.05)'
  } else {
    return 'rgba(0,0,0,0.05)'
  }
})

watch(
  () => colorMode.value,
  (newVal) => {
    options.value.colors = [lineColor.value, barColor.value]
    options.value.fill.colors = ['rgba(92, 48, 219, .65)', barColor.value]
    options.value.grid.borderColor = gridColor.value
    options.value.xaxis.labels.style.colors = textColor.value
    refChart.value.updateOptions(options.value)
  }
)

const options = ref({
  chart: {
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    },
    selection: {
      enabled: false
    }
  },
  legend: {
    show: false
  },
  colors: [lineColor.value, barColor.value],
  fill: {
    type: ['solid', 'solid'],
    colors: ['rgba(92, 48, 219, .65)', barColor.value]
  },
  stroke: {
    width: [2, 0]
  },
  title: {},
  labels: days.value,
  grid: {
    show: true,
    borderColor: gridColor.value,
    xaxis: {
      lines: {
        show: true
      }
    },
    yaxis: {
      lines: {
        show: true
      }
    }
  },
  xaxis: {
    type: 'datetime',

    axisTicks: {
      show: false
    },
    axisBorder: {
      show: false
    },
    labels: {
      style: {
        colors: textColor.value,
        fontFamily: `'DiaType', sans-serif`,
        fontSize: '8px',
        fontWeight: 500
      },
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
        day: 'MMM dd',
        hour: 'HH:mm'
      }
    }
  },

  yaxis: [
    {
      show: false,
      min: 0,
      max: (max) => {
        if (max === 0) {
          return 1
        } else {
          return max * 1.2
        }
      }
    },
    {
      opposite: false,
      show: false,
      min: 0,
      max: (max) => {
        if (max === 0) {
          return 1
        } else {
          return max * 1.2
        }
      }
    }
  ],
  zoom: {
    enabled: false
  },
  plotOptions: {
    bar: {
      borderRadius: 5
    }
  },
  tooltip: {
    theme: 'dark',
    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      if (props.readMode) {
        return `<div class="gt">
  <div class="gt-top">${new Date(days.value[dataPointIndex]).toLocaleDateString(
    'en-US',
    {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    }
  )}</div>
  
  <div class="gt-row">
    <span class="gt-row__label">Updates:</span> ${series[1][
      dataPointIndex
    ].toFixed(0)}</div>
        </div>`
      } else {
        return `<div class="gt">
  <div class="gt-top">${new Date(days.value[dataPointIndex]).toLocaleDateString(
    'en-US',
    {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    }
  )}</div>
  <div class="gt-row">
    <span class="gt-row__label">${
      texts.value[1].label
    }</span> <span>${formatLowNumbers(
      series[0][dataPointIndex]
    )}</span> <div class="gt-row__icon"></div> </div>
  <div class="gt-row">
    <span class="gt-row__label">${texts.value[2].label}</span> ${series[1][
      dataPointIndex
    ].toFixed(0)}</div>
        </div>`
      }
    }
  }
})

watch(
  series,
  (newVal) => {
    if (newVal[0].data.length > 0) {
      showGraph.value = true
    }
  }
  // { immediate: true }
)

watch(
  () => props.showDays,
  (newVal) => {
    days.value = generateDays(newVal)
    options.value.labels = days.value
    refChart.value.updateOptions(options.value)
  }
)
</script>

<style lang="scss" scoped>
.graph-wrapper {
  padding: 25px 9px;
  border-radius: 10px;
  border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
  background: var(--Box-bg, rgba(255, 255, 255, 0));
  overflow: hidden;
  max-width: 100%;
}

.graph {
  @media (max-width: 1023px) {
    max-width: calc(100vw - 80px);
    overflow-x: auto;
    height: 370px;

    :deep(.vue-apexcharts) {
      min-width: 600px;
    }
  }
}

.graph-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--Text-60, rgba(0, 0, 0, 0.6));
  padding-inline: 16px;
}

.legend {
  display: flex;
  gap: 15px;
  line-height: 100%;
}

.legend-txs,
.legend-gas {
  display: flex;
  gap: 7px;
  align-items: center;

  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-bottom: 2px;
  }
}

.legend-txs {
  &::before {
    background: #5c30db4d;
  }
}

.legend-gas {
  &::before {
    background: #3d6ef6;
  }
}
</style>
<style lang="scss">
.graph-wrapper {
  --icon-url: '';
  .vue-apexcharts {
    padding-left: 8px;
    padding-right: 18px;
  }
}

.gt {
  border: 0;
  border-radius: 10px;
  background: #1a2646;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  padding: 10px;
  font-size: 14px;
  letter-spacing: 0.1px;
  font-weight: 500;
}

.gt-top {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.gt-row {
  padding-right: 30px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 5px;

  &__icon {
    width: 12px;
    height: 12px;
    background: var(--icon-url) no-repeat center center;
    background-size: contain;
    margin-bottom: 2px;
  }

  &:last-child {
    margin-bottom: 0;
  }
  &__label {
    opacity: 0.6;
    font-weight: 400;
  }
}
</style>
