<template>
  <div class="docu-bar">
    <div class="title">
      <div class="icon" v-html="QuestionSvg" />
      <div class="text" v-html="texts?.text" />
    </div>
    <div class="buttons">
      <a
        v-for="item in texts?.buttons"
        :href="item.link"
        class="btn grey-with-border"
        target="_blank"
      >
        {{ item.label }}
      </a>
    </div>
  </div>
</template>

<script setup>
import QuestionSvg from '~/assets/icons/question.svg?raw'
const textsStore = useTextsStore()

const texts = computed(() => {
  return textsStore.list?.documentation
})
</script>

<style lang="scss" scoped>
.docu-bar {
  border-radius: 10px;
  border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
  background: var(--Block-bg, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 750px) {
    flex-direction: column;
    gap: 20px;
    padding: 15px 5px;
  }

  @media (min-width: 1024px) {
  }

  .title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;

    .icon {
      display: block;
      position: relative;
      top: -1px;

      :deep(path) {
        stroke: var(--Text, #000);
      }
    }
  }

  .buttons {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 750px) {
      gap: 10px;
    }
  }
}
</style>
