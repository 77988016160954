export const useGetFeedAggregation = async (
  address,
  chainId,
  feed,
  feedSelection
) => {
  const newOraclesStore = useNewOraclesStore()

  const GET_FEED_AGGREGATION = gql`
    query GetFeedAggregation(
      $startTime: Time!
      $endTime: Time!
      $feedSelection: [FeedSelection!]
    ) {
      GetFeedAggregation(
        StartTime: $startTime
        EndTime: $endTime
        FeedSelection: $feedSelection
      ) {
        Exchange
        LastPrice
        Basetokenaddress
        Basetokenblockchain
        Basetokensymbol
        Quotetokensymbol
        Quotetokenaddress
        Quotetokenblockchain
        Pooladdress
        PoolLiquidityUSD
        Volume
        TradesCount
        Time
      }
    }
  `
  const now = Math.floor(Date.now() / 1000)
  if (
    !newOraclesStore.feedAggregations[address + '-' + chainId + '-' + feed]
      ?.GetFeedAggregation
  ) {
    const { data } = await useAsyncQuery(GET_FEED_AGGREGATION, {
      startTime: now - 3600 * 1, //TODO: change to 3600 * 24
      endTime: now,
      feedSelection: feedSelection
    })
    newOraclesStore.feedAggregations[address + '-' + chainId + '-' + feed] = {}
    newOraclesStore.feedAggregations[
      address + '-' + chainId + '-' + feed
    ].GetFeedAggregation = data.value?.GetFeedAggregation

    return true
  } else {
    return true
  }
}
