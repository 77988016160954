<template>
  <button
    type="button"
    class="dfr data-row mobile-info"
    @click.prevent="openDetails"
  >
    <div class="dfr__col asset text-16-18 asset">
      <div class="asset-icons">
        <img
          :src="getTokenIconUrl(props.item.Symbol)"
          width="30"
          height="30"
          alt=""
          class="main-icon"
        />
      </div>
      <div>
        {{ props.item.Symbol }} / USD
        <div class="mobile-price">
          <span v-if="props.item.LastReportedPrice === ''">-</span>
          <span v-else>
            $
            {{ formatPrice(parseInt(props.item.LastReportedPrice) / 10 ** 8) }}
          </span>
        </div>
      </div>
    </div>
    <div class="dfr__col udpate mobile-hide">
      <!-- TODO: change to real value -->
      {{ shortenAddress(props.oracle.address) }}
      <DeployerUiCopy :copy-value="props.oracle.address" class="ml5" />
    </div>
    <div class="dfr__col volume mobile-hide">
      <div v-if="props.item.Deviation">
        {{ props.item.Deviation }}% deviation
      </div>
      <div
        :class="{
          'with-deviation': props.item.Deviation !== ''
        }"
      >
        {{ countHeartbeat(props.item.HeartBeat) }} heartbeat
      </div>
    </div>
    <div class="dfr__col last-price mobile-hide">
      <span v-if="props.item.LastReportedPrice === ''">-</span>
      <span v-else>
        $ {{ formatPrice(parseInt(props.item.LastReportedPrice) / 10 ** 8) }}
      </span>
    </div>
    <div class="dfr__col last-update">
      <div v-if="props.item.LastReport !== '0001-01-01T00:00:00Z'">
        <div class="">
          {{ parseDate(props.item.LastReportedTime).date }}
        </div>
        <div class="time">
          {{ parseDate(props.item.LastReportedTime).time }}
        </div>
      </div>
      <div v-else>
        <div class="text-12 opacity-70">-</div>
      </div>
    </div>
    <div class="dfr__col chevron mobile-hide" v-html="ChevronSvg" />
  </button>
</template>

<script setup>
import ChevronSvg from '~/assets/icons/chevron-right.svg?raw'

const newOraclesStore = useNewOraclesStore()

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  oracle: Object,
  readMode: {
    type: Boolean,
    default: false
  }
})

const feedUrl = computed(() => {
  if (props.loading) {
    return '#'
  }
  // return `/oracle-analytics/oracle/${route.params.address}/${route.params.chainId}/${props.item.asset}`
})

const openDetails = () => {
  newOraclesStore.selectedFeed = {
    address: props.oracle.address,
    chainId: props.oracle.chainId,
    symbol: props.item.Symbol,
    lastPrice: props.item.LastReportedPrice,
    lastReportedTime: props.item.LastReportedTime,
    deviation: props.item.Deviation,
    heartbeat: props.item.HeartBeat,
    readMode: props.readMode
  }

  newOraclesStore.showFeedDetails = true
}

const isLoading = ref(true)

useGetFeedAggregation(
  props.oracle.address,
  props.oracle.chainId,
  props.item.Symbol,
  props.item.SymbolFeed.FeedSelection
)

const totalVolume = computed(() => {
  return newOraclesStore.getFeedTotalVolume(
    props.oracle.address,
    props.oracle.chainId,
    props.item.Symbol
  )
})
</script>

<style lang="scss" scoped>
.last-update {
  .time {
    opacity: 0.5;
    margin-top: 5px;
  }
}

.with-deviation {
  opacity: 0.5;
  margin-top: 5px;
}

.chevron {
  text-align: right;
  :deep(path) {
    stroke: var(--Text, #000);
  }
}

.mobile-price {
  opacity: 0.5;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 5px;
  @media (min-width: 1024px) {
    display: none;
  }
}
</style>
