export const useEditOracle = async (oracle, signedData) => {
  const config = useRuntimeConfig()

  const formdata = new FormData()

  formdata.append('oracleaddress', oracle.Address)
  formdata.append('chainID', oracle.ChainID)
  formdata.append('creator', oracle.Owner)
  formdata.append('signeddata', signedData)
  formdata.append('symbols', oracle.Symbols.join(','))
  formdata.append('sleepseconds', oracle.SleepSeconds)
  formdata.append('feederID', oracle.FeederID)
  formdata.append('name', oracle.Name)

  if (parseFloat(oracle.DeviationPermille) > 0) {
    formdata.append('mandatoryfrequency', oracle.Frequency)
    formdata.append('frequency', '0')
  } else {
    formdata.append('mandatoryfrequency', '0')
    formdata.append('frequency', oracle.Frequency)
  }

  formdata.append('feedselection', oracle.FeederSelection)

  formdata.append('deviationpermille', oracle.DeviationPermille)

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  }

  try {
    const res = await fetch(
      `${config.public.oracleApiBaseURL}/oraclebuilder-prod/create`,
      requestOptions
    )
    const d = await res.json()

    return d.publicKey
  } catch (err) {
    console.error(err)
    throw new Error('Failed to create and register oracle')
  }
}
